import logo from "./logo.svg";
import "./css/App.css";
import { AppContext } from "./context/appContext";
import { SessionStorageProvider } from "./context/SessionStorageContext";

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
  split,
  ApolloLink,
} from "@apollo/client";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useEffect } from "react";
import Main from "./components/Main";
import Swal from "sweetalert2";
import ReactGA from "react-ga";
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      if (
        error?.extensions?.exception?.stacktrace?.[0]?.split(":")[0] ==
        "TokenExpiredError"
      ) {
        Swal.fire({
          icon: "error",
          title: "Token expired",
          text: "Please login again",
        }).then(() => {
          //we can't use useNavigate here
          window.localStorage.clear();
          window.location.href = "/login";
        });
      }
    });

  // if (networkError) console.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_IS_PROD == "true"
      ? process.env.REACT_APP_SERVER_URL
      : "http://localhost:8888/.netlify/functions/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AppContext.Provider value={{}}>
      <ApolloProvider client={client}>
        <SessionStorageProvider>
          <Router>
            <Main />
          </Router>
        </SessionStorageProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export default App;
