import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Signout() {
  localStorage.removeItem("token");
  if (window.location.href.includes("signout")) {
    window.location.href = "/";
  } else {
    window.location.reload();
  }
}

export default Signout;
