import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import queries from "../queries";
import {
  calculateSubtotal,
  calculateTotal,
  getCurrCurrency,
  haveLoginToken,
  orderStatus,
  paymentStatusColor,
  productStatus,
  setPageTitle,
} from "../common";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./wishlist.css";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";
import moment from "moment";

function Orders({ dataSelf, error, refetch }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    getOrders,
    { data: orderData, loading: orderLoading, error: orderError },
  ] = useLazyQuery(queries.member.GET_PURCHASED_ORDERS, {
    variables: { page: 1 },
  });
  const navigate = useNavigate();
  setPageTitle("My Orders");
  const [addProductToOrders] = useMutation(
    queries.member.ADD_PRODUCT_TO_ORDERS
  );

  useEffect(() => {
    if (!haveLoginToken()) {
      navigate("/");
    }
    getOrders();
    handlePendingPurchase();
  }, []);

  useEffect(() => {
    if (orderData != null) {
      ImportMainJs();
    }
  }, [orderData]);

  async function handlePendingPurchase() {
    const status = searchParams.get("status");
    const session_id = searchParams.get("session_id");
    if (status == "cancel") {
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
      });
    } else if (session_id != null) {
      try {
        const { data } = await addProductToOrders({
          variables: {
            sessionId: session_id,
          },
        });

        getOrders();
        Swal.fire({
          icon: "success",
          title: "Order placed successfully",
        }).then(() => {
          window.location.href = "/my-orders";
        });
      } catch (e) {
        if (e) {
          e = {
            message: e,
          };
        }
        ////////////ERROR IS SOLVED!!!  REMOVE THIS ONCE SATISFIED
        //GIANT JUGAD FOR TEMPORARILY AVOIDING ERROR DISPLAY
        if (e.message.graphQLErrors[0].message === "Order already placed") {
          ///GIANT JUGAD FOR TEMPORARILY AVOIDING ERROR DISPLAY
          Swal.fire("Order already placed", "", "success").then(() => {
            window.location.href = "/my-orders";
          });
        } else {
          Swal.fire({
            icon: "error",
            title: e.message,
          });
        }
      }
    }
  }

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">My Orders</li>
          </ul>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container">
          <h2 className="text-center">My Orders</h2>
          {dataSelf?.getSelf?.purchased?.length == 0 && (
            <div className="text-center">
              <h5 className="mt-5">
                Your haven't shopped with us yet. Checkout our most purchased
                products.
              </h5>
              <a className="btn btn-primary text-center" href="/products">
                Shop Now
              </a>
            </div>
          )}
          {dataSelf?.getSelf?.purchased?.map((purchasedItem) => {
            return (
              <div className="row order-item">
                <div className="col-12 order-item-title">
                  <div className="row">
                    <div className="col-md-8">
                      <h4>#{purchasedItem?._id}</h4>
                      <span
                        className="order-item-status"
                        style={{
                          backgroundColor:
                            paymentStatusColor[purchasedItem?.status],
                        }}
                      >
                        {orderStatus[purchasedItem?.status]}
                      </span>
                      {" " +
                        moment(purchasedItem?.date).format(
                          "Do MMMM, YYYY hh:MM a"
                        )}
                    </div>
                    <div className="col-md-4 text-right text-muted">
                      {purchasedItem?.tracking && (
                        <div className="col text-right">
                          <a
                            target="_blank"
                            href={purchasedItem?.tracking}
                            className="btn btn-primary"
                          >
                            Track Order
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="cart-main">
                    {purchasedItem?.products?.map((product) => {
                      return (
                        <div className="cart-item py-1 px-4 border-bottom-0">
                          <figure className="cart-item-col cart-item-col-figure">
                            <a
                              className="link-image rounded"
                              href={`/product/${product?.product_id}`}
                            >
                              <img
                                src={product?.product?.image?.[0]}
                                alt=""
                                width="260"
                                height="312"
                              />
                            </a>
                          </figure>
                          <div className="cart-item-col cart-item-col-content">
                            <div className="cart-item-title">
                              <a href={`/product/${product?.product_id}`}>
                                {product?.product?.name}{" "}
                                {product?.options?.length > 0 ? " - " : ""}
                                {product?.options?.map((o) => o.value + " ")}
                              </a>
                            </div>
                          </div>
                          <div className="cart-item-col-price">
                            {getCurrCurrency()}
                            {product?.price} x {product?.qty} {"= "}
                            <span className="cart-subtitle font-weight-bold">
                              {getCurrCurrency()}
                              {product?.price * product?.qty}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12 order-item-footer">
                  <div className="row">
                    <div className="col">
                      To, <br />
                      {purchasedItem?.address?.line1 +
                        ", " +
                        purchasedItem?.address?.state +
                        ", " +
                        purchasedItem?.address?.postal_code}
                    </div>
                    <div className="mr-4">
                      {purchasedItem?.taxAmount && (
                        <div className="row mt-0 mr-0">
                          <span className="col">Taxes:&nbsp;</span>
                          <span className="font-weight-bold cart-subtitle">
                            {getCurrCurrency()}
                            {purchasedItem?.taxAmount}
                          </span>
                        </div>
                      )}
                      {purchasedItem?.shippingAmount && (
                        <div className="row mt-0 mr-0">
                          <span className="col">Shipping:&nbsp;</span>
                          <span className="font-weight-bold cart-subtitle">
                            {getCurrCurrency()}
                            {purchasedItem?.shippingAmount}
                          </span>
                        </div>
                      )}
                      <div className="row mt-0 mr-0">
                        <span className="col">Total:&nbsp;</span>
                        <span className="font-weight-bold cart-subtitle">
                          {getCurrCurrency()}
                          {purchasedItem?.amount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Orders;
