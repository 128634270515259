import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { calculateSubtotal, getCurrCurrency, localCartKey } from "../../common";
import queries from "../../queries";
import { handleRemoveProductFromCart } from "../mini/AddToCart";
import { getInitialCart } from "../../helper/LocalCartHelper";
import { useSessionStorage } from "../../context/SessionStorageContext";

function MiniCart({ dataSelf, loading, error, refetch }) {
  const [removeProductFromCart] = useMutation(
    queries.member.REMOVE_PRODUCT_FROM_CART
  );
  // const [fetchLocalCart, { data: dataLocalCart }] = useLazyQuery(
  //   queries.member.GET_LOCAL_CART
  // );
  const [cart, setCart] = useState([]);
  const { sessionData, updateSessionStorage, isUserLoggedIn, localCart } =
    useSessionStorage();

  useEffect(() => {
    if (dataSelf?.getSelf != null) {
      setCart(dataSelf?.getSelf?.cart);
    }
  }, [dataSelf]);

  useEffect(() => {
    setCart(localCart);
  }, [localCart]);

  return (
    <div className="navbar-cart">
      <h4 className="navbar-cart-title">Cart</h4>
      <div className="navbar-cart-item-group">
        {cart?.length == 0 && <span>You shopping cart it empty</span>}
        {cart?.map((cartItem) => {
          return (
            <div className="navbar-cart-item">
              <div className="navbar-cart-item-left thumbnail">
                <a
                  className="thumbnail-small link-image"
                  href={`/product/${cartItem?.product_id}`}
                >
                  <img
                    src={cartItem?.product?.image?.[0]}
                    alt={cartItem?.product?.name}
                    width="260"
                    height="312"
                  />
                </a>
              </div>
              <div className="navbar-cart-item-body">
                <a
                  className="navbar-cart-item-heading"
                  href={`/product/${cartItem?.product_id}`}
                >
                  {cartItem?.product?.name}{" "}
                  {cartItem?.options?.length > 0 ? " - " : ""}
                  {cartItem?.options?.map((o) => o?.value + " ")}
                </a>
                <div className="navbar-cart-item-price">
                  {getCurrCurrency()}
                  {cartItem?.price} x {cartItem?.qty}
                </div>
              </div>
              <button
                className="navbar-cart-remove"
                onClick={async () => {
                  await handleRemoveProductFromCart(
                    updateSessionStorage,
                    removeProductFromCart,
                    isUserLoggedIn,
                    cartItem
                  );
                  refetch();
                }}
              >
                <svg
                  className="icon-svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.2713 5.86121L9.92761 5.81241L9.6363 13.691L10.98 13.7396L11.2713 5.86121Z"></path>
                  <path d="M8.67226 5.83681H7.32774V13.7154H8.67226V5.83681Z"></path>
                  <path d="M6.36346 13.6908L6.07216 5.81217L4.7285 5.86102L5.01984 13.7396L6.36346 13.6908Z"></path>
                  <path d="M0 2.55222V3.8838H1.40102L2.51247 16.3926C2.54294 16.7363 2.83361 17 3.18205 17H12.7953C13.1438 17 13.4346 16.7361 13.4649 16.3923L14.5764 3.8838H16V2.55222H0ZM12.1795 15.6684H3.79765L2.75071 3.8838H13.2267L12.1795 15.6684Z"></path>
                  <path d="M10.1961 0H5.80392C5.1861 0 4.68348 0.497781 4.68348 1.10965V3.21801H6.028V1.33158H9.972V3.21801H11.3165V1.10965C11.3165 0.497781 10.8139 0 10.1961 0Z"></path>
                </svg>
              </button>
            </div>
          );
        })}
      </div>
      <div className="navbar-cart-total">
        Subtotal:{" "}
        <span className="text-primary">
          {getCurrCurrency()}
          {calculateSubtotal(cart)}
        </span>
      </div>
      {cart?.length > 0 && (
        <a
          className="btn btn-primary navbar-cart-btn btn-block"
          href="/checkout"
        >
          Checkout
        </a>
      )}
      <a className="btn btn-gray-800 navbar-cart-btn btn-block" href="/cart">
        View Cart
      </a>
    </div>
  );
}

export default MiniCart;
