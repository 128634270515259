import { gql } from "@apollo/client";

const products = {
  GET_PRODUCTS: gql`
    query GetProducts($page: Int!) {
      getProducts(page: $page) {
        _id
        additional_info {
          title
          value
        }
        barcode
        date_added
        discount
        from_price
        old_from_price
        image
        name
        options {
          title
          values {
            images
            price
            old_price
            stock
            title
          }
        }
        popularity
        qna {
          answer
          question
        }
        rating
        reviews {
          _id
          date_added
          member_id
          member {
            fname
            lname
          }
          rating
          review
        }
        short_description
        stock
        sku
        status
        tags
        type
        subtype
      }
    }
  `,
  GET_PRODUCTS_BY_QUERY: gql`
    query GetProductsByQuery(
      $page: Int!
      $search: String
      $productQuery: ProductQueryArgs
      $sortBy: String
    ) {
      getProductsByQuery(
        page: $page
        search: $search
        productQuery: $productQuery
        sort_by: $sortBy
      ) {
        type
        subtype
        tags
        status
        sku
        _id
        name
        image
        barcode
        from_price
        old_from_price
        discount
        rating
        short_description
        stock

        date_added
        popularity
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
      }
    }
  `,
  GET_PRODUCTS_BY_PATH: gql`
    query GetProductByPath($path: String!) {
      getProductByPath(path: $path) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  ADD_REVIEW: gql`
    mutation AddReview($productId: String!, $reviewArgs: ReviewArgs!) {
      addReview(productId: $productId, reviewArgs: $reviewArgs) {
        _id
        review
        member_id
        member {
          fname
          lname
        }
        date_added
        rating
      }
    }
  `,

  GET_TRENDING_PRODUCTS: gql`
    query GetTrendingProducts {
      getTrendingProducts {
        type
        products {
          _id
          type
          subtype
          sku
          name
          image
          barcode
          from_price
          old_from_price
          discount
          options {
            title
            values {
              title
              price
              old_price
              stock
              images
            }
          }
          rating
          short_description
          additional_info {
            title
            value
          }
          qna {
            question
            answer
          }
          date_added
          status
          tags
          popularity
        }
      }
    }
  `,
  GET_PRODUCT: gql`
    query GetProduct($productId: String!) {
      getProduct(productId: $productId) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        rating
        reviews {
          _id
          review
          date_added
          member_id
          member {
            fname
            lname
          }
          rating
        }
        short_description
        stock
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
        additional_info {
          title
          value
        }
      }
    }
  `,
  UPDATE_REVIEW: gql`
    mutation UpdateReview($updateReviewArgs: UpdateReviewArgs!) {
      updateReview(updateReviewArgs: $updateReviewArgs) {
        _id
        review
        member_id
        member {
          fname
          lname
        }
        date_added
        rating
      }
    }
  `,
  REMOVE_REVIEW: gql`
    mutation RemoveReview($reviewId: String!) {
      removeReview(reviewId: $reviewId)
    }
  `,
  ADD_PRODUCT: gql`
    mutation AddProduct($productArgs: ProductArgs!) {
      addProduct(productArgs: $productArgs) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          values {
            title
            price
            old_price
            stock
            images
          }
          title
        }
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  UPDATE_PRODUCT: gql`
    mutation UpdateProduct(
      $productId: String!
      $updateProductArgs: UpdateProductArgs!
    ) {
      updateProduct(
        productId: $productId
        updateProductArgs: $updateProductArgs
      ) {
        _id
        type
        subtype
        sku
        image
        name
        barcode
        from_price
        old_from_price
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        discount
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          rating
          date_added
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  REMOVE_PRODUCT: gql`
    mutation RemoveProduct($productId: String!) {
      removeProduct(productId: $productId) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            images
            price
            old_price
            stock
            title
          }
        }
        rating
        reviews {
          _id
          review
          member_id
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          answer
          question
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  GET_NEW_PRODUCTS: gql`
    query GetNewProducts($type: Int!) {
      getNewProducts(type: $type) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  GET_NEW_ARRIVALS: gql`
    query GetNewArrivals {
      getNewArrivals {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
  GET_SPECIAL_OFFERS: gql`
    query GetSpecialOffers($type: Int!) {
      getSpecialOffers(type: $type) {
        _id
        type
        subtype
        sku
        name
        image
        barcode
        from_price
        old_from_price
        discount
        options {
          title
          values {
            title
            price
            old_price
            stock
            images
          }
        }
        rating
        reviews {
          _id
          review
          member_id
          member {
            fname
            lname
          }
          date_added
          rating
        }
        short_description
        stock
        additional_info {
          title
          value
        }
        qna {
          question
          answer
        }
        date_added
        status
        tags
        popularity
      }
    }
  `,
};
const member = {
  SIGNUP: gql`
    mutation Signup($signupArgs: SignupArg!) {
      signup(signupArgs: $signupArgs) {
        status
        message
        detail
      }
    }
  `,
  LOGIN: gql`
    mutation Login($email: String!, $password: String!) {
      login(email: $email, password: $password) {
        __typename
        ... on LoginResponse {
          token
          member {
            _id
            fname
            lname
            email
            password
            date_joined
            type {
              type
            }
            cart {
              _id
            }
            wishlist {
              _id
            }
          }
        }
        ... on CommonResponse {
          status
          message
          detail
        }
      }
    }
  `,
  EMAIL_VERIFICATION: gql`
    mutation EmailVerification($token: String!, $otp: String!) {
      emailVerification(token: $token, otp: $otp) {
        token
        member {
          _id
          fname
          lname
          email
          password
          date_joined
          type {
            _id
            type
            role_access
          }
          cart {
            _id
            product_id
            product {
              _id
              type
              subtype
              sku
              name
              image
              barcode
              from_price
              old_from_price
              discount
              options {
                title
                values {
                  title
                  price
                  old_price
                  images
                  stock
                }
              }
              rating
              reviews {
                _id
                review
                member_id
                date_added
                rating
              }
              short_description
              additional_info {
                title
                value
              }
              qna {
                question
                answer
              }
              date_added
              status
              tags
              stock
              popularity
            }
            options {
              title
              value
            }
            qty
            price
          }
          wishlist {
            _id
            product_id
          }
          purchased {
            _id
            date
            status
            paymentStatus
            amount
            address {
              line1
              city
              state
              country
              postal_code
            }
            products {
              _id
              product_id
              qty
              price
            }
            note
            txnId
            taxAmount
            shippingAmount
            tracking
          }
        }
      }
    }
  `,
  ADD_PRODUCT_TO_WISHLIST: gql`
    mutation AddProductToWishlist($productId: String!) {
      addProductToWishlist(productId: $productId) {
        _id
        product_id
        product {
          name
          _id
          type
          subtype
          sku
          image
          barcode
          from_price
          old_from_price
          discount
          options {
            title
            values {
              title
              price
              old_price
              stock
              images
            }
          }
          rating
          short_description
          stock
          status
          tags
          popularity
        }
      }
    }
  `,
  REMOVE_PRODUCT_FROM_WISHLIST: gql`
    mutation RemoveProductFromWishlist($wishlistId: String!) {
      removeProductFromWishlist(wishlistId: $wishlistId)
    }
  `,
  ADD_PRODUCT_TO_CART: gql`
    mutation AddProductToCart(
      $productId: String!
      $qty: Int!
      $options: [CartOptionArgs]
    ) {
      addProductToCart(productId: $productId, qty: $qty, options: $options) {
        _id
        product_id
        product {
          _id
          type
          subtype
          sku
          name
          barcode
          image
          from_price
          old_from_price
          discount
          options {
            title
            values {
              title
              price
              old_price
              stock
              images
            }
          }
          rating
          short_description
          stock
          date_added
          status
          tags
          popularity
        }
        price
        qty
      }
    }
  `,
  INC_QTY_TO_CART: gql`
    mutation IncQtyToCart($cartId: String!, $incBy: Int!) {
      incQtyToCart(cartId: $cartId, incBy: $incBy)
    }
  `,
  REMOVE_PRODUCT_FROM_CART: gql`
    mutation RemoveProductFromCart($cartId: String!) {
      removeProductFromCart(cartId: $cartId)
    }
  `,
  GET_SELF: gql`
    query GetSelf {
      getSelf {
        _id
        fname
        lname
        email
        password
        date_joined
        type {
          _id
          type
          role_access
        }
        cart {
          _id
          product_id
          product {
            name
            sku
            type
            subtype
            _id
            image
            barcode
            discount
            from_price
            old_from_price
            options {
              title
              values {
                title
                price
                old_price
                stock
                images
              }
            }
            rating
            short_description
            stock
            date_added
            status
            tags
            popularity
          }
          options {
            title
            value
          }
          price
          qty
        }
        wishlist {
          _id
          product_id
          product {
            _id
            type
            subtype
            sku
            name
            image
            barcode
            from_price
            old_from_price
            discount
            options {
              title
              values {
                title
                price
                old_price
                stock
                images
              }
            }
            rating
            short_description
            stock
            date_added
            status
            tags
            popularity
          }
        }
        purchased {
          _id
          date
          status
          paymentStatus
          amount
          address {
            line1
            city
            state
            country
            postal_code
          }
          products {
            _id
            product_id
            product {
              _id
              type
              sku
              name
              image
              barcode
              from_price
              old_from_price
              discount
              options {
                title
                values {
                  title
                  price
                  old_price
                  stock
                  images
                }
              }
              rating
              reviews {
                _id
                review
                member_id
                date_added
                rating
              }
              short_description
              stock
              additional_info {
                title
                value
              }
              qna {
                question
                answer
              }
              date_added
              status
              tags
              popularity
            }
            options {
              title
              value
            }
            qty
            price
          }
          note
          txnId
          taxAmount
          shippingAmount
          tracking
        }
      }
    }
  `,

  GET_PURCHASED_ORDERS: gql`
    query GetPurchasedOrders($page: Int!) {
      getPurchasedOrders(page: $page) {
        _id
        date
        status
        paymentStatus
        amount
        address {
          line1
          city
          state
          country
          postal_code
        }
        products {
          _id
          product_id
          price
          product {
            name
            image
            barcode
            from_price
            old_from_price
            discount
            options {
              title
              values {
                title
                price
                old_price
                stock
                images
              }
            }
            rating
            short_description
            stock
            status
            popularity
            tags
            date_added
            sku
            type
            subtype
            _id
          }
          options {
            title
            value
          }
          qty
        }
        note
        txnId
        taxAmount
        shippingAmount
        member {
          _id
          fname
          lname
          email
        }
        tracking
      }
    }
  `,
  GET_LOCAL_CART: gql`
    query GetLocalCart($localCartArgs: [LocalCartArg]!) {
      getLocalCart(localCartArgs: $localCartArgs) {
        _id
        product_id
        qty
        price
        options {
          title
          value
        }
        product {
          _id
          type
          subtype
          sku
          name
          image
          barcode
          from_price
          old_from_price
          discount
          options {
            title
            values {
              title
              price
              old_price
              images
              stock
            }
          }
          rating
          reviews {
            _id
            review
            member_id
            member {
              _id
              fname
              lname
              email
              password
              date_joined
              type {
                _id
                type
                role_access
              }
              cart {
                _id
                product_id
                product {
                  _id
                  type
                  subtype
                  sku
                  name
                  image
                  barcode
                  from_price
                  old_from_price
                  discount
                  rating
                  short_description
                  date_added
                  status
                  tags
                  stock
                  popularity
                }
                options {
                  title
                  value
                }
                qty
                price
              }
              wishlist {
                _id
                product_id
              }
              purchased {
                _id
                date
                status
                paymentStatus
                amount
                address {
                  line1
                  city
                  state
                  country
                  postal_code
                }
                products {
                  _id
                  product_id
                  qty
                  price
                }
                note
                txnId
                taxAmount
                shippingAmount
                tracking
              }
            }
            date_added
            rating
          }
          short_description
          additional_info {
            title
            value
          }
          qna {
            question
            answer
          }
          date_added
          status
          tags
          stock
          popularity
        }
      }
    }
  `,
  ADD_PRODUCT_TO_ORDERS: gql`
    mutation AddProductToOrders($sessionId: String!) {
      addProductToOrders(sessionId: $sessionId) {
        _id
        date
        status
        paymentStatus
        amount
        address {
          line1
          city
          state
          country
          postal_code
        }
        products {
          _id
          product_id
          product {
            _id
            type
            subtype
            sku
            name
            image
            barcode
            from_price
            old_from_price
            discount
            options {
              title
              values {
                title
                price
                old_price
                stock
                images
              }
            }
            rating
            reviews {
              _id
              review
              member_id
              member {
                fname
                lname
              }
              date_added
              rating
            }
            short_description
            stock
            additional_info {
              title
              value
            }
            qna {
              question
              answer
            }
            date_added
            status
            tags
            popularity
          }
          options {
            title
            value
          }
          qty
          price
        }
        note
        txnId
        taxAmount
        shippingAmount
      }
    }
  `,

  UPDATE_CART_OPTIONS: gql`
    mutation UpdateCart($cartId: String!, $newOption: CartOptionArgs!) {
      updateCart(cartId: $cartId, newOption: $newOption)
    }
  `,
  REQUEST_PASSWORD_RESET: gql`
    mutation RequestPasswordReset($email: String!) {
      requestPasswordReset(email: $email)
    }
  `,
  RESET_PASSWORD: gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
      resetPassword(token: $token, newPassword: $newPassword)
    }
  `,
};

const general = {
  PLACE_ORDER_BY_AUTH_TOKEN: gql`
    mutation PlaceOrderByAuthToken(
      $checkoutSessionArgs: CheckoutSessionArgs!
      $token: String!
      $email: String!
    ) {
      placeOrderByAuthToken(
        checkoutSessionArgs: $checkoutSessionArgs
        token: $token
        email: $email
      ) {
        _id
        date
        status
        paymentStatus
        amount
        address {
          line1
          city
          state
          country
          postal_code
        }
        products {
          _id
          product_id
          product {
            _id
            type
            subtype
            sku
            name
            image
            barcode
            from_price
            old_from_price
            discount
            options {
              title
              values {
                title
                price
                old_price
                stock
                images
              }
            }
            rating
            reviews {
              _id
              review
              member_id
              member {
                fname
                lname
              }
              date_added
              rating
            }
            short_description
            stock
            additional_info {
              title
              value
            }
            qna {
              question
              answer
            }
            date_added
            status
            tags
            popularity
          }
          options {
            title
            value
          }
          qty
          price
        }
        note
        txnId
        taxAmount
        shippingAmount
      }
    }
  `,
  CREATE_CHECKOUT_SESSION: gql`
    mutation CreateCheckoutSession($checkoutSessionArgs: CheckoutSessionArgs) {
      createCheckoutSession(checkoutSessionArgs: $checkoutSessionArgs)
    }
  `,
  GET_COUNTRIES: gql`
    query GetCountries {
      getCountries {
        country_name
        country_short_name
        country_phone_code
      }
    }
  `,
  GET_STATES: gql`
    query GetStates($country: String) {
      getStates(country: $country) {
        state_name
      }
    }
  `,
  GET_CITIES: gql`
    query GetCities($state: String) {
      getCities(state: $state) {
        city_name
      }
    }
  `,
  UPDATE_ORDER_STATUS: gql`
    mutation UpdateOrderStatus(
      $newStatus: Int!
      $purchasedId: String!
      $trackingUrl: String
    ) {
      updateOrderStatus(
        newStatus: $newStatus
        purchasedId: $purchasedId
        trackingUrl: $trackingUrl
      )
    }
  `,
};
let exported = {
  products,
  member,
  general,
};

export default exported;
