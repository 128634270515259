import { useMutation } from "@apollo/client";
import React from "react";
import { getCurrCurrency } from "../../common";
import { handleAddProductToCart } from "./AddToCart";
import queries from "../../queries";
import { useSessionStorage } from "../../context/SessionStorageContext";

function ProductCard({ product, refetch }) {
  const [addProductToCart] = useMutation(queries.member.ADD_PRODUCT_TO_CART);
  const { sessionData, updateSessionStorage, isUserLoggedIn } =
    useSessionStorage();
  function handleAddToCartClick(product) {
    const defaultOption = {
      title: product?.options?.[0]?.title,
      value: product?.options?.[0]?.values?.[0]?.title,
    };
    handleAddProductToCart(
      updateSessionStorage,
      isUserLoggedIn,
      addProductToCart,
      product._id,
      1,
      defaultOption.title != null && defaultOption.value != null
        ? [defaultOption]
        : [],
      refetch
    );
  }

  return (
    <article className="product-item-card">
      <div className="product-item-card-header">
        <figure className="product-item-card-figure">
          <a className="link-image rounded" href={`/product/${product?._id}`}>
            <img src={product?.image?.[0]} alt="" className="img-product" />
          </a>
        </figure>
        <ul className="product-item-badges">
          {product?.tags?.includes("new") && (
            <li className="product-badge-new mb-1">New</li>
          )}
          {product?.tags?.includes("sale") && (
            <li className="product-badge">Sale</li>
          )}
        </ul>
      </div>
      <div className="product-item-card-caption">
        <h6 className="product-item-card-title">
          <a href={`/product/${product?._id}`}>{product?.name}</a>
        </h6>
        <div className="flex flex-col">
          {product?.old_from_price > product?.from_price && (
            <span className="product-item-card-price-old mr-2">
              {getCurrCurrency()}
              {product?.old_from_price}
            </span>
          )}
          <span className="product-item-card-price">
            {getCurrCurrency()}
            {product?.from_price}
          </span>
        </div>
        <div className="product-item-card-btn">
          <a
            className="btn btn-primary btn-sm"
            href={null}
            onClick={(e) => {
              e.preventDefault();
              handleAddToCartClick(product);
            }}
          >
            Add to cart
          </a>
        </div>
      </div>
    </article>
  );
}

export default ProductCard;
