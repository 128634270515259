import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import queries from "../queries";
import common, {
  calculateSubtotal,
  calculateTotal,
  getCurrCurrency,
  haveLoginToken,
  setPageTitle,
  showLoading,
} from "../common";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";
import tokenizeCard from "../helper/Tokenization";
import { useSessionStorage } from "../context/SessionStorageContext";
import * as LocalCartHelper from "../helper/LocalCartHelper";

function Checkout({ dataSelf, loading, error, refetch }) {
  const navigate = useNavigate();
  // const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  setPageTitle("Checkout");
  const [cart, setCart] = useState([]);
  const { sessionData, updateSessionStorage, isUserLoggedIn, localCart } =
    useSessionStorage();

  useEffect(() => {
    if (dataSelf != null) {
      ImportMainJs();
      setCart(dataSelf?.getSelf?.cart);
      setFName(dataSelf?.getSelf?.fname);
      setLName(dataSelf?.getSelf?.lname);
      setEmail(dataSelf?.getSelf?.email);
    }
  }, [dataSelf]);

  useEffect(() => {
    setCart(localCart);
  }, [localCart]);

  const [createCheckoutSession] = useMutation(
    queries.general.CREATE_CHECKOUT_SESSION
  );
  const [placeOrderByAuthToken] = useMutation(
    queries.general.PLACE_ORDER_BY_AUTH_TOKEN
  );

  const [country, setCountry] = useState("United States");
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [fname, setFName] = useState();
  const [email, setEmail] = useState();
  const [lname, setLName] = useState();
  const [line1, setLine1] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [cvv, setCVV] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [postal_code, setPostalCode] = useState();
  const [phone, setPhone] = useState();
  const [note, setNote] = useState();

  const {
    data: dataCountries,
    loading: loadingCountries,
    error: errorCountries,
  } = useQuery(queries.general.GET_COUNTRIES);
  const {
    data: dataStates,
    loading: loadingStates,
    error: errorStates,
  } = useQuery(queries.general.GET_STATES, {
    variables: {
      country: country,
    },
  });
  const {
    data: dataCities,
    loading: loadingCities,
    error: errorCities,
  } = useQuery(queries.general.GET_CITIES, {
    variables: {
      state: state,
    },
  });

  async function handlePlaceOrder(e) {
    e.preventDefault();
    if (validateForm()) {
      showLoading("Checkout in progress...");
      let products = cart.map((item) => {
        let temp = item;
        if (temp.options != null && temp.options != []) {
          temp.options.forEach((ele) => {
            delete ele.__typename;
          });
        }
        return {
          _id: item.product_id,
          type: item.product.type,
          subtype: item.product.subtype,
          qty: item.qty,
          options: temp.options,
        };
      });
      const checkoutArgs = {
        checkoutSessionArgs: {
          fname,
          lname,
          address: {
            line1,
            state,
            city,
            country,
            postal_code,
          },
          note: note,
          products: products,
          currency: "USD",
          phone: phone,
        },
      };
      try {
        const { data } = await createCheckoutSession({
          variables: checkoutArgs,
        });
        window.location.href = data?.createCheckoutSession;
      } catch (error) {
        if (typeof error == "string")
          error = {
            message: error,
          };
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      }
    }
  }

  async function handlePlaceOrderByAuthToken(e) {
    e.preventDefault();
    if (validateForm()) {
      showLoading("Checkout in progress...");

      let products = cart.map((item) => {
        let temp = item;
        if (temp.options != null && temp.options != []) {
          temp.options.forEach((ele) => {
            delete ele.__typename;
          });
        }
        return {
          _id: item.product_id,
          type: item.product.type,
          subtype: item.product.subtype,
          qty: item.qty,
          options: temp.options,
        };
      });
      const checkoutSessionArgs = {
        fname,
        lname,
        address: {
          line1,
          state: state.toUpperCase(),
          city,
          country,
          postal_code,
        },
        note: note,
        products: products,
        currency: "USD",
        phone: phone,
      };

      try {
        const cardToken = await tokenizeCard(cardNumber, expiryDate, cvv);
        const { data } = await placeOrderByAuthToken({
          variables: {
            checkoutSessionArgs: checkoutSessionArgs,
            token: cardToken,
            email: email,
          },
        });
        if (data?.placeOrderByAuthToken) {
          Swal.fire({
            icon: "success",
            title: "Order placed successfully",
          }).then(() => {
            LocalCartHelper.clearLocalCart(updateSessionStorage);
            window.location.href = "/my-orders";
          });
        } else {
          throw new Error("Could not place the order at the moment.");
        }
      } catch (error) {
        if (typeof error == "string")
          error = {
            message: error,
          };
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      }
    }
  }

  function validateForm() {
    const errors = [];
    if (email == null || email.trim() === "") {
      errors.push("Please enter email");
    } else if (!common.isValidEmail(email)) {
      errors.push("Please enter valid email");
    }
    if (country == null || country.trim() == "") {
      errors.push("Please select country");
    }
    if (state == null || state.trim() == "") {
      errors.push("Please select state");
    }
    if (city == null || city.trim() == "") {
      errors.push("Please select city");
    }
    if (fname == null || fname.trim() == "") {
      errors.push("Please enter first name");
    }
    if (lname == null || lname.trim() == "") {
      errors.push("Please enter last name");
    }
    if (line1 == null || line1.trim() == "") {
      errors.push("Please enter street address");
    }
    if (postal_code == null || postal_code.trim() == "") {
      errors.push("Please enter postal code");
    }
    if (
      phone != null &&
      (phone.trim() == "" ||
        isNaN(phone?.replaceAll("-", "")) ||
        phone?.trim()?.length != 12)
    ) {
      errors.push("Please enter valid phone");
    }
    if (cardNumber == null || cardNumber.trim() === "") {
      errors.push("Please enter Card Number");
    }
    if (expiryDate == null || expiryDate.trim() === "") {
      errors.push("Please enter Card Expiry Date");
    }
    if (cvv == null || cvv.trim() === "") {
      errors.push("Please enter Card CVV");
    }
    if (errors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Please correct these errors in the form before submitting",
        html: errors.join("<br/>"),
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    // const observerCountry = new MutationObserver((mutationsList) => {
    //   for (const mutation of mutationsList) {
    //     if (
    //       mutation.type === "childList" ||
    //       mutation.type === "characterData"
    //     ) {
    //       // Content of the div has changed
    //       const country = mutation?.target?.title;
    //       if (country != null) {
    //         setCountry(country);
    //       }
    //     }
    //   }
    // });

    // observerCountry.observe(countryRef.current, {
    //   childList: true,
    //   subtree: true,
    //   characterData: true,
    // });

    const observerState = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          // Content of the div has changed
          const state = mutation?.target?.title;
          if (state != null) {
            setState(state);
          }
        }
      }
    });

    observerState.observe(stateRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    const observerCity = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          // Content of the div has changed
          const city = mutation?.target?.title;
          if (city != null) {
            setCity(city);
          }
        }
      }
    });

    observerCity.observe(cityRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      // observerCountry.disconnect();
      observerState.disconnect();
      observerCity.disconnect();
    };
  }, []);

  useEffect(() => {
    if (dataCountries?.getCountries != null) {
      setCountry("United States");
    }
  }, [dataCountries]);

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">Checkout</li>
          </ul>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container">
          <h2 className="text-center">Checkout</h2>
          <div className="row justify-content-center">
            {/* <div className="checkout-box">
                <h4>Have a coupon?</h4>
                <div
                  className="small coupon-box-toggle"
                  data-custom-toggle=".coupon-box"
                >
                  Click here to enter your code
                </div>
                <div className="coupon-box">
                  <h5>If you have a coupon code, please apply it below.</h5>
                  <form
                    className="rd-form mailchimp-mailform rd-form-inline rd-form-inline-2"
                    action="#"
                    method="post"
                  >
                    <div className="form-wrap">
                      <label
                        className="form-label form-label-outside"
                        for="checkout-coupon"
                      >
                        Coupon code:
                      </label>
                      <input
                        className="form-input"
                        id="checkout-coupon"
                        type="email"
                        name="email"
                        placeholder="Coupon"
                      />
                    </div>
                    <div className="form-button">
                      <button className="btn btn-gray-800" type="submit">
                        Apply Coupon
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
            <div className="checkout-box col-lg-6 mb-4">
              <div className="row row-20">
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-name"
                    >
                      First name *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-name"
                      data-constraints="@Required"
                      type="text"
                      name="name"
                      value={fname}
                      onChange={(e) => setFName(e.target.value)}
                      placeholder="Jane"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-name-last"
                    >
                      Last name *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-name-last"
                      data-constraints="@Required"
                      type="text"
                      name="name"
                      value={lname}
                      onChange={(e) => setLName(e.target.value)}
                      placeholder="Doe"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-address"
                    >
                      Street address *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-address"
                      data-constraints="@Required"
                      type="text"
                      name="name"
                      value={line1}
                      onChange={(e) => setLine1(e.target.value)}
                      placeholder="123 Main Street"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-wrap" ref={cityRef}>
                    <label
                      className="form-label form-label-outside"
                      for="checkout-city"
                    >
                      City *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-city"
                      data-constraints="@Required"
                      type="text"
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="New York City"
                    />
                    {/* <select id="checkout-city">
                    {dataCities?.getCities?.map((c) => {
                      return (
                        <option selected={city == c.city_name}>
                          {c.city_name}
                        </option>
                      );
                    })}
                  </select> */}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-wrap" ref={stateRef}>
                    <label
                      className="form-label form-label-outside"
                      for="checkout-state"
                    >
                      State *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-state"
                      data-constraints="@Required"
                      style={{ textTransform: "uppercase" }}
                      type="text"
                      name="state"
                      value={state}
                      maxLength={2}
                      autoCapitalize="characters"
                      onChange={(e) => setState(e.target.value)}
                      placeholder="NY"
                    />
                    {/* <select id="checkout-state">
                    {dataStates?.getStates?.map((s) => {
                      return (
                        <option selected={state == s.state_name}>
                          {s.state_name}
                        </option>
                      );
                    })}
                  </select> */}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-postcode"
                    >
                      Postcode *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-postcode"
                      data-constraints="@Required"
                      type="text"
                      name="name"
                      value={postal_code}
                      maxLength={5}
                      onChange={(e) =>
                        setPostalCode(e.target.value.replace(/\D/g, ""))
                      }
                      placeholder="10007"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-phone"
                    >
                      Phone (optional)
                    </label>
                    <input
                      className="form-input"
                      id="checkout-phone"
                      type="phone"
                      name="name"
                      value={phone}
                      placeholder="234-555-1234"
                      onChange={(event) => {
                        let input = event.target.value;
                        input = input.replace(/\D/g, ""); // remove non-numeric characters

                        // restrict input to maximum of 12 digits
                        if (input.length > 10) {
                          input = input.slice(0, 10);
                        }

                        let formattedInput = "";

                        if (input.length > 3 && input.length <= 6) {
                          formattedInput =
                            input.slice(0, 3) + "-" + input.slice(3);
                        } else if (input.length > 6) {
                          formattedInput =
                            input.slice(0, 3) +
                            "-" +
                            input.slice(3, 6) +
                            "-" +
                            input.slice(6, 12);
                        } else {
                          formattedInput = input;
                        }

                        setPhone(formattedInput);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-info"
                    >
                      Order notes (optional)
                    </label>
                    <textarea
                      className="form-input"
                      id="checkout-info"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="e.g. Special notes for delivery"
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* <div className="form-wrap" ref={countryRef}>
                  <label
                    className="form-label form-label-outside"
                    for="checkout-country"
                  >
                    Country *
                  </label>
                  <select id="checkout-country" value={country}>
                    {dataCountries?.getCountries?.map((c) => {
                      return <option>{c.country_name}</option>;
                    })}
                  </select>
                </div> */}
            </div>
            <div className="checkout-box col-lg-6">
              <div className="row row-20">
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-name"
                    >
                      Email *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-email"
                      type="email"
                      name="email"
                      data-constraints="@Email @Required"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="jane.doe@gmail.com"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-address"
                    >
                      Card Number *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-cardnumber"
                      data-constraints="@Required"
                      type="text"
                      name="cardnumber"
                      value={cardNumber}
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, "");
                        const formattedCardNumber =
                          input.match(/.{1,4}/g)?.join(" ") || "";
                        setCardNumber(formattedCardNumber);
                      }}
                      maxLength={19}
                      placeholder="1234 1234 1234 1234"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-address"
                    >
                      Expiry Date *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-expirydate"
                      data-constraints="@Required"
                      type="text"
                      name="expirydate"
                      value={expiryDate}
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                        let formattedExpDate = input;

                        if (input.length >= 2) {
                          let month = parseInt(input.slice(0, 2), 10);

                          if (month > 12) {
                            month = 12; // Set to December if month is greater than 12
                          }

                          formattedExpDate = month.toString().padStart(2, "0"); // Format month

                          if (input.length > 2) {
                            const year = input.slice(2, 4);
                            formattedExpDate += `/${year}`;
                          }
                        }

                        setExpiryDate(formattedExpDate);
                      }}
                      maxLength={5}
                      placeholder="MM/YY"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      for="checkout-address"
                    >
                      CVC *
                    </label>
                    <input
                      className="form-input"
                      id="checkout-cvc"
                      data-constraints="@Required"
                      type="text"
                      name="cvc"
                      value={cvv}
                      onChange={(e) => {
                        setCVV(e.target.value.replace(/\D/g, ""));
                      }}
                      maxLength={4}
                      placeholder="1234"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  * We prioritize your security. We do not store your card
                  information; it is only used to securely process your order.
                </div>
              </div>
              <div className="table-custom-responsive">
                <table className="table-order">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th></th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.map((cartItem) => {
                      return (
                        <tr>
                          <td>
                            {cartItem?.product.name}{" "}
                            {cartItem?.options?.length > 0 ? " - " : ""}
                            {cartItem?.options?.map((o) => o.value + " ")}
                          </td>
                          <td>x {cartItem?.qty}</td>
                          <td>
                            {getCurrCurrency()}
                            {cartItem?.price * cartItem?.qty}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="font-weight-medium">Subtotal:</td>
                      <td className="font-weight-bold text-primary">
                        {getCurrCurrency()}
                        {calculateSubtotal(cart)}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="font-weight-medium">Total:</td>
                      <td className="font-weight-bold text-primary">
                        {getCurrCurrency()}
                        {calculateTotal(cart)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="text-right mt-5">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={handlePlaceOrderByAuthToken}
                >
                  Place Order
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Checkout;
