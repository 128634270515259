import { useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { haveLoginToken, setPageTitle } from "../common";
import queries from "../queries";
import { ImportMainJs } from "../helper/ImportScript";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login] = useMutation(queries.member.LOGIN);
  const [emailVerification] = useMutation(queries.member.EMAIL_VERIFICATION);
  const [requestPasswordReset] = useMutation(
    queries.member.REQUEST_PASSWORD_RESET
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirectUrl, setRedirectUrl] = useState(
    searchParams.get("redirect_url")
  );

  useEffect(() => {
    ImportMainJs();
  }, []);

  function handleResetPassword() {
    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      text: "You are about to request for a password reset.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await requestPasswordReset({
            variables: {
              email: email,
            },
          });
          Swal.fire({
            icon: "success",
            title: data?.requestPasswordReset
              ? data?.requestPasswordReset
              : "Password reset request initiated.",
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: e.message,
          });
        }
      }
    });
  }

  async function handleLogin(e) {
    e.preventDefault();
    try {
      const { data: dataLogin } = await login({
        variables: {
          email,
          password,
        },
      });
      if (
        dataLogin?.login?.__typename === "LoginResponse" &&
        dataLogin?.login?.member !== null &&
        dataLogin?.login?.token != null
      ) {
        localStorage.setItem("token", dataLogin?.login?.token);
        window.location.href = redirectUrl?.length > 0 ? redirectUrl : "/";
      } else if (dataLogin?.login?.__typename === "CommonResponse") {
        // OTP has been sent
        Swal.fire({
          title: "Enter OTP for Email Verification",
          input: "text",
          inputPlaceholder: "e.g. 000000",
          inputValidator: (value) => {
            if (!value || value.length !== 6) {
              return "Please enter valid OTP";
            }
          },
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const { data: dataEmailVerification } = await emailVerification({
                variables: {
                  token: dataLogin?.login?.detail,
                  otp: result.value,
                },
              });
              if (
                dataEmailVerification?.emailVerification?.member !== null &&
                dataEmailVerification?.emailVerification?.token != null
              ) {
                localStorage.setItem(
                  "token",
                  dataEmailVerification?.emailVerification?.token
                );
                window.location.href =
                  redirectUrl?.length > 0 ? redirectUrl : "/";
              }
            } catch (error) {
              if (typeof e == "string") {
                e = {
                  message: e,
                };
              }
              Swal.fire({
                icon: "error",
                title: "Email could not be verified. Please try again.",
              });
            }
          }
        });
      }
    } catch (e) {
      if (typeof e == "string") {
        e = {
          message: e,
        };
      }
      Swal.fire({
        icon: "error",
        title: e.message,
      });
    }
  }

  setPageTitle("Login");

  useEffect(() => {
    if (haveLoginToken()) {
      window.location.href = redirectUrl?.length > 0 ? redirectUrl : "/";
    }
  }, []);

  return (
    <>
      {/* <div className="hiraola-login-register_area">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xs-12">
              <form action="#">
                <div className="login-form">
                  <h4 className="login-title">Login</h4>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <label>Email Address*</label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb--20">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-md-8" hidden={true}>
                      <div className="check-box">
                        <input type="checkbox" id="remember_me" />
                        <label htmlFor="remember_me">Remember me</label>
                      </div>
                    </div>
                    <div className="col-md-4" hidden={true}>
                      <div className="forgotton-password_info">
                        <a href="#"> Forgotten password?</a>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="hiraola-login_btn" onClick={handleLogin}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <section className="section section-md bg-white text-center">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-9 col-lg-7 col-xl-5">
              <h3>Login</h3>
              {/* <div className="group-sm group-custom">
                <a
                  className="btn btn-login btn-facebook btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-facebook"></span>Facebook
                </a>
                <a
                  className="btn btn-login btn-twitter btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-twitter"></span>Twitter
                </a>
                <a
                  className="btn btn-login btn-google btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-google-plus"></span>Google+
                </a>
              </div> */}
              {/* <div className="text-decoration-lines">
                <span className="text-decoration-lines-content">or</span>
              </div> */}

              <form className="rd-form rd-mailform rd-form-centered">
                <div className="form-wrap">
                  <input
                    className="form-input"
                    id="login-email"
                    type="email"
                    name="email"
                    data-constraints="@Email @Required"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="form-label" htmlFor="login-email">
                    E-mail
                  </label>
                </div>
                <div className="form-wrap">
                  <input
                    className="form-input"
                    id="login-password"
                    type="password"
                    name="password"
                    data-constraints="@Required"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="form-label" htmlFor="login-password">
                    Password
                  </label>
                </div>
                <a
                  className="small text-right mt-1"
                  href="javascript:void(0);"
                  onClick={handleResetPassword}
                >
                  Forgot password?
                </a>
                <button
                  className="btn btn-block btn-primary mt-3"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </form>
              <p className="big">
                Don't have an account? <a href="/signup">Signup</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
