const { default: Swal } = require("sweetalert2");

let currCurrency = "$";
module.exports = {
  setCurrCurrency: (newCurrency) => {
    currCurrency = newCurrency;
  },
  getCurrCurrency: () => {
    return currCurrency;
  },
  localCartKey: "spc-ims",
  currency: {
    USD: "$",
    INR: "₹",
  },
  memberType: {
    SUPER_ADMIN: 1, // Developers only
    ADMIN: 2, // Owners of Star Dist
    EMPLOYEE: 3, // Employee of Star Dist
    CUSTOMER: 4, // B2C users
    BUSINESS: 5, // B2B users
  },
  permissions: {
    MODIFY_ADMIN: "MODIFY_ADMIN",
    MODIFY_EMPLOYEE: "MODIFY_EMPLOYEE",
    MODIFY_ACCESS: "MODIFY_ACCESS",
    MODIFY_PRODUCTS: "MODIFY_PRODUCTS",
    MANAGE_ORDERS: "MANAGE_ORDERS",
  },
  paymentStatus: {
    0: "PENDING",
    1: "PAID",
    2: "FAILED",
  },
  //   productType: {
  //     JEWELRY: 1,
  //     GEMSTONE: 2,
  //   },
  orderStatus: {
    0: "Ordered",
    1: "Placed",
    2: "Shipped",
    3: "Delivered",
    4: "Pending",
  },
  orderStatusName: {
    Ordered: 0,
    Placed: 1,
    Shipped: 2,
    Delivered: 3,
    Pending: 4,
  },
  paymentStatusColor: {
    0: "#F0AD4E",
    1: "#5BC0DE",
    2: "#337AB7",
    3: "#5CB85C",
    4: "#D9534F",
  },
  //   shopBanner: {
  //     Rings: "/assets/images/breadcrumb/ring.jpg",
  //     "Engagement Rings": "/assets/images/breadcrumb/engagement-ring.jpg",
  //     Pendants: "/assets/images/breadcrumb/pendant.png",
  //     Earrings: "/assets/images/breadcrumb/earring.jpg",
  //     Bracelets: "/assets/images/breadcrumb/bracelet.jpg",
  //     Necklaces: "/assets/images/breadcrumb/necklace.jpg",
  //     Bangles: "/assets/images/breadcrumb/bracelet.jpg",
  //     Wishlist: "/assets/images/breadcrumb/wishlist.jpg",
  //     Cart: "/assets/images/breadcrumb/cart.jpg",
  //     Shop: "/assets/images/breadcrumb/cart.jpg",
  //     Education: "/assets/images/breadcrumb/education.jpg",
  //     About: "/assets/images/breadcrumb/about.jpg",
  //     CustomJewelry: "/assets/images/breadcrumb/custom-jewelry.jpg",
  //     Gemstone: "/assets/images/breadcrumb/gemstone.jpg",
  //   },
  //   imgComingSoon: "/assets/images/uploads/coming-soon.jpg",
  //   placementType: {
  //     CENTER: 1,
  //     SURROUNDING: 2,
  //   },
  //   placementTypeText: {
  //     1: "Center",
  //     2: "Surrounding",
  //   },
  //   gemstoneType: {
  //     NATURAL: 1,
  //     LAB_GROWN: 2,
  //   },
  //   gemstoneTypeText: {
  //     1: "Natural",
  //     2: "Lab Grown",
  //   },
  productType: {
    KRATOM: 1,
    CBD: 2,
    DELTA8: 3,
    EDIBLES: 4,
    GLASS: 5,
    HOOKAH: 6,
    NICOTINE: 7,
    ROLLING_PAPER: 8,
    HEMP_WRAPS: 9,
    TORCH_IT: 10,
    ACCESSORIES: 11,
    BEST_SELLER: 12,
    NEW_ARRIVAL: 13,
    SPECIAL_DEALS: 14,
  },
  productSubtype: {
    KRATOM: {
      LIQUID_EXTRACT_SHOTS: 15,
      POWDER: 16,
      CAPSULES: 17,
    },
    CBD: {
      GUMMIES: 18,
      CBD_FLOWERS: 19,
      HEMP_CIGARETTE: 20,
    },
    DELTA8: {
      VAPE_DISPOSABLES: 21,
      CARTIDGES: 22,
      FLOWERS: 23,
      PREROLL: 24,
      VAPE_PENSZ: 25,
    },
    EDIBLES: 4,
    GLASS: {
      WATERPIPE: 26,
      HAND_PIPE: 27,
      RIGS: 28,
    },
    HOOKAH: {
      HOOKAHS: 29,
      CHARCOAL: 30,
      HOOKAH_ACCESSORIES: 31,
    },
    NICOTINE: {
      E_LIQUID: 32,
      DISPOSABLES: 33,
      NICOTINE_DELIVERY_SYSTEM: 34,
      VAPE_PENS: 35,
    },
    ROLLING_PAPER: {
      EZ_WIDER: 36,
      RAW_PAPERS: 37,
      BAMBOO_PAPERS: 38,
      EZ_WIDER_CONE: 39,
      RAW_CONE: 40,
      OCB_BAMBOO_CONE: 41,
      EZ_OTHER_BRANDS: 42,
    },
    HEMP_WRAPS: {
      HIGH_HEMP: 43,
      TRUE_HEMP: 44,
    },
    TORCH_IT: {
      BUTANE_LIGHTER_FUEL: 45,
      BIG_TORCHES: 46,
      MINI_TORCHES: 47,
      LIGHTERS: 48,
    },
    ACCESSORIES: {
      BOWLS_N_BANGERS: 49,
      LIGHTER_LEASH: 50,
      INCENCE: 51,
      AIR_SPRAY: 52,
      CLEANERS_N_SOLUTIONS: 53,
      GRINDERS: 54,
      SCALES: 55,
      ASHTRAYS: 56,
      BUTT_BUCKET: 57,
      UPASS_XSTREAM: 58,
      ROLLING_MACHNES: 59,
    },
    BEST_SELLER: 12,
    NEW_ARRIVAL: 13,
    SPECIAL_DEALS: 14,
  },
  productSortBy: {
    BEST_SELLER: "BEST_SELLER",
    NEWEST_FIRST: "NEWEST_FIRST",
    PRICE_LOW_TO_HIGH: "PRICE_LOW_TO_HIGH",
    PRICE_HIGH_TO_LOW: "PRICE_HIGH_TO_LOW",
    MOST_DISCOUNT: "MOST_DISCOUNT",
  },
  productSortByNames: {
    BEST_SELLER: "Best Seller",
    NEWEST_FIRST: "Newest First",
    PRICE_LOW_TO_HIGH: "Price Low - High",
    PRICE_HIGH_TO_LOW: "Price High - Low",
    MOST_DISCOUNT: "Discount",
  },
  productTypeURL: {
    1: "KRATOM",
    2: "CBD",
    3: "DELTA8",
    4: "EDIBLES",
    5: "GLASS",
    6: "HOOKAH",
    7: "NICOTINE",
    8: "ROLLING_PAPER",
    9: "HEMP_WRAPS",
    10: "TORCH_IT",
    11: "ACCESSORIES",
    12: "BEST_SELLER",
    13: "NEW_ARRIVAL",
    14: "SPECIAL_DEALS",
  },
  productTypeDisplayNames: {
    1: "Kratom",
    2: "CBD",
    3: "Δ8",
    4: "Edibles",
    5: "Glass",
    6: "Hookah",
    7: "Nicotine",
    8: "Rolling Papers",
    9: "Hemp Wraps",
    10: "Torch It",
    11: "Accessories",
    12: "Best Seller",
    13: "New Arrival",
    14: "Special Deals",
  },
  productSubtypeURL: {
    KRATOM: {
      15: "LIQUID_EXTRACT_SHOTS",
      16: "POWDER",
      17: "CAPSULES",
    },
    CBD: {
      18: "GUMMIES",
      19: "CBD_FLOWERS",
      20: "HEMP_CIGARETTE",
    },
    DELTA8: {
      21: "VAPE_DISPOSABLES",
      22: "CARTIDGES",
      23: "FLOWERS",
      24: "PREROLL",
      25: "VAPE_PENSZ",
    },
    EDIBLES: 4,
    GLASS: {
      26: "WATERPIPE",
      27: "HAND_PIPE",
      28: "RIGS",
    },
    HOOKAH: {
      29: "HOOKAHS",
      30: "CHARCOAL",
      31: "HOOKAH_ACCESSORIES",
    },
    NICOTINE: {
      32: "E_LIQUID",
      33: "DISPOSABLES",
      34: "NICOTINE_DELIVERY_SYSTEM",
      35: "VAPE_PENS",
    },
    ROLLING_PAPER: {
      36: "EZ_WIDER",
      37: "RAW_PAPERS",
      38: "BAMBOO_PAPERS",
      39: "EZ_WIDER_CONE",
      40: "RAW_CONE",
      41: "OCB_BAMBOO_CONE",
      42: "EZ_OTHER_BRANDS",
    },
    HEMP_WRAPS: {
      43: "HIGH_HEMP",
      44: "TRUE_HEMP",
    },
    TORCH_IT: {
      45: "BUTANE_LIGHTER_FUEL",
      46: "BIG_TORCHES",
      47: "MINI_TORCHES",
      48: "LIGHTERS",
    },
    ACCESSORIES: {
      49: "BOWLS_N_BANGERS",
      50: "LI GHTER_LEASH",
      51: "INCENCE",
      52: "AIR_SPRAY",
      53: "CLEANERS_N_SOLUTIONS",
      54: "GRINDERS",
      55: "SCALES",
      56: "ASHTRAYS",
      57: "BUTT_BUCKET",
      58: "UPASS_XSTREAM",
      59: "ROLLING_MACHNES",
    },
    BEST_SELLER: 12,
    NEW_ARRIVAL: 13,
    SPECIAL_DEALS: 14,
  },
  productSubtypeDisplayNames: {
    KRATOM: {
      15: "Liquid Extract Shots",
      16: "Powder",
      17: "Capsules",
    },
    CBD: {
      18: "Gummies",
      19: "CBD Flowers",
      20: "Hemp Cigaretter",
    },
    DELTA8: {
      21: "Vape Disposables",
      22: "Cartidges",
      23: "Flowers",
      24: "Preroll",
      25: "Vape Pensz",
    },
    EDIBLES: 4,
    GLASS: {
      26: "Waterpipe",
      27: "Hand Pipe",
      28: "Rings",
    },
    HOOKAH: {
      29: "Hookahs",
      30: "Charcoal",
      31: "Hookah Accessories",
    },
    NICOTINE: {
      32: "E-Liquid",
      33: "Disposables",
      34: "Nicotine Delivery System",
      35: "Vape Pens",
    },
    ROLLING_PAPER: {
      36: "EZ Wider",
      37: "Raw Papers",
      38: "Bamboo Papers",
      39: "EZ Wider Cone",
      40: "Raw Cone",
      41: "OCB Bamboo Cone",
      42: "EZ Other Brands",
    },
    HEMP_WRAPS: {
      43: "High Hemp",
      44: "True Hemp",
    },
    TORCH_IT: {
      45: "Butane Lighter Fuel",
      46: "Big Torches",
      47: "Mini Torches",
      48: "Lighters",
    },
    ACCESSORIES: {
      49: "Bowls & Bangers",
      50: "Lighter Lease",
      51: "Incence",
      52: "Air Spray",
      53: "Cleaners & Solutions",
      54: "Grinders",
      55: "Scales",
      56: "Ashtrays",
      57: "Butt Bucket",
      58: "UPass XStream",
      59: "Rolling Machines",
    },
  },
  productStatus: {
    ACTIVE: 1,
    REMOVED: 2,
  },
  haveLoginToken: () => {
    return localStorage.getItem("token") != null;
  },
  calculateSubtotal: (cart) => {
    if (!cart) cart = [];
    var subtotal = 0;
    cart.forEach((cartItem) => {
      subtotal += cartItem.price * cartItem.qty;
    });
    return subtotal.toFixed(2);
  },
  calculateTotal: (cart) => {
    if (!cart) cart = [];
    let total = 0;
    cart.forEach((cartItem) => {
      total += cartItem.price * cartItem.qty;
    });
    return total.toFixed(2);
  },
  showLoading: (message) => {
    if (message == null) message = "Loading...";
    Swal.fire({
      title: message,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  },
  isMobileScreen: () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  },
  closeLoading: () => {
    Swal.close();
  },
  dateDifference: (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  },
  isUserAbove21: () => {
    return localStorage.getItem("age_gate") == 21;
  },
  setPageTitle: (text) => {
    if (text?.length > 0) document.title = "Snoopydoopy.com - " + text;
    else document.title = "Snoopydoopy.com - Best Online Kratom Source";
  },
  isValidEmail: (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  MIN_TRENDING_PRODUCT: 4,
  COUNT_PER_PAGE: 21,
};
