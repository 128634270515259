import { localCartKey } from "../common";
import { decrypt, encrypt } from "./EncrHelper";
import _ from "lodash";

export const addToCart = (updateSessionStorage, cartItem) => {
  const encryptedCart = localStorage.getItem(localCartKey);
  let plainTextCart = encryptedCart ? JSON.parse(decrypt(encryptedCart)) : [];
  if (!plainTextCart) plainTextCart = [];

  let existingCartItemIndex = plainTextCart.findIndex(
    (i) =>
      i.product_id === cartItem.product_id &&
      _.isMatch(cartItem.options, i.options)
  );
  if (existingCartItemIndex !== -1)
    plainTextCart[existingCartItemIndex].qty += 1;
  else plainTextCart.push(cartItem);

  updateSessionStorage(localCartKey, plainTextCart);
};

export const incQuantity = (updateSessionStorage, cartItem, qty) => {
  const encryptedCart = localStorage.getItem(localCartKey);
  let plainTextCart = encryptedCart ? JSON.parse(decrypt(encryptedCart)) : [];
  if (!plainTextCart) plainTextCart = [];

  let existingCartItemIndex = plainTextCart.findIndex(
    (i) =>
      i.product_id === cartItem.product_id &&
      _.isMatch(cartItem.options, i.options)
  );
  if (existingCartItemIndex !== -1) {
    plainTextCart[existingCartItemIndex].qty += qty;
    updateSessionStorage(localCartKey, plainTextCart);
  }
};

export const removeFromCart = (updateSessionStorage, cartItem) => {
  const encryptedCart = localStorage.getItem(localCartKey);
  let plainTextCart = encryptedCart ? JSON.parse(decrypt(encryptedCart)) : [];
  if (!plainTextCart) plainTextCart = [];

  let existingCartItemIndex = plainTextCart.findIndex((i) =>
    _.isMatch(cartItem, i)
  );
  if (existingCartItemIndex !== -1) {
    plainTextCart.splice(existingCartItemIndex, 1);
  }

  updateSessionStorage(localCartKey, plainTextCart);
};

export const clearLocalCart = (updateSessionStorage) => {
  updateSessionStorage(localCartKey, []);
};

export const getInitialCart = () => {
  const encryptedCart = localStorage.getItem(localCartKey);
  let plainTextCart = encryptedCart ? JSON.parse(decrypt(encryptedCart)) : [];
  if (!plainTextCart) plainTextCart = [];
  return plainTextCart;
};
