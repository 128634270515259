import React, { createContext, useContext, useEffect, useState } from "react";
import { encrypt } from "../helper/EncrHelper";
import { getInitialCart } from "../helper/LocalCartHelper";
import queries from "../queries";
import { useLazyQuery, useQuery } from "@apollo/client";

const SessionStorageContext = createContext();

export const SessionStorageProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState(() => getInitialCart());
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [localCart, setLocalCart] = useState([]);
  const [fetchLocalCart, { data: dataLocalCart }] = useLazyQuery(
    queries.member.GET_LOCAL_CART,
    {
      variables: {
        localCartArgs: [],
      },
    }
  );

  useEffect(() => {
    if (
      Array.isArray(dataLocalCart?.getLocalCart) &&
      dataLocalCart?.getLocalCart.length > 0
    ) {
      setLocalCart(dataLocalCart?.getLocalCart);
    } else {
      setLocalCart([]);
    }
  }, [dataLocalCart]);

  const { data, loading, error } = useQuery(queries.member.GET_SELF, {
    errorPolicy: "all",
    onError: (error) => {
      const loginSuccess =
        error?.networkError?.result?.errors?.[0]?.extensions?.code !==
        "UNAUTHENTICATED";
      setIsUserLoggedIn(loginSuccess);
      if (!loginSuccess) {
        reloadLocalCart(getInitialCart());
      }
    },
    onCompleted: (data) => {
      const loginSuccess = data?.getSelf !== null;
      setIsUserLoggedIn(loginSuccess);
      if (!loginSuccess) {
        reloadLocalCart(getInitialCart());
      }
    },
  });

  const reloadLocalCart = (localCartArgs) => {
    fetchLocalCart({
      variables: {
        localCartArgs: localCartArgs,
      },
    });
  };

  // Function to update session storage and notify components
  const updateSessionStorage = (key, value) => {
    localStorage.setItem(key, encrypt(JSON.stringify(value))); // encryptedCart

    reloadLocalCart(value);

    window.dispatchEvent(
      new CustomEvent("sessionStorageUpdated", {
        detail: {
          ...sessionData,
          [key]: value,
        },
      })
    );
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.storageArea === sessionStorage) {
        setSessionData({}); // Trigger re-renders in all components
      }
    };

    const handleCustomEvent = (event) => {
      setSessionData(event.detail);
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("sessionStorageUpdated", handleCustomEvent);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("sessionStorageUpdated", handleCustomEvent);
    };
  }, []);

  return (
    <SessionStorageContext.Provider
      value={{
        sessionData,
        updateSessionStorage,
        isUserLoggedIn,
        setIsUserLoggedIn,
        localCart,
      }}
    >
      {children}
    </SessionStorageContext.Provider>
  );
};

export const useSessionStorage = () => useContext(SessionStorageContext);
