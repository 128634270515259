import React from "react";
import { useState } from "react";

export const Size = {
  SMALL: 19,
  MEDIUM: 21,
  LARGE: 30,
};

function Rating({ rating, editable, size, onRatingUpdate }) {
  rating = parseFloat(rating);
  if (isNaN(rating)) {
    rating = 0;
  }
  if (editable == null) editable = false;
  if (size == null) size = Size.MEDIUM;
  const [currRating, setCurrRating] = useState(rating);

  return (
    <div className="rating-body">
      <div className="rating-empty">
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(1);
              onRatingUpdate(1);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#F0F4F6"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(2);
              onRatingUpdate(2);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#F0F4F6"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(3);
              onRatingUpdate(3);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#F0F4F6"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(4);
              onRatingUpdate(4);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#F0F4F6"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(5);
              onRatingUpdate(5);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#F0F4F6"
          ></path>
        </svg>
      </div>
      <div className="rating-fill" style={{ width: currRating * 20 + "%" }}>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(1);
              onRatingUpdate(1);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#FFC42D"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(2);
              onRatingUpdate(2);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#FFC42D"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(3);
              onRatingUpdate(3);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#FFC42D"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(4);
              onRatingUpdate(4);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#FFC42D"
          ></path>
        </svg>
        <svg
          width={`${size}`}
          height={`${size}`}
          viewBox="0 0 13 13"
          onClick={(e) => {
            e.preventDefault();
            if (editable) {
              setCurrRating(5);
              onRatingUpdate(5);
            }
          }}
        >
          <path
            d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
            fill="#FFC42D"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Rating;
