import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queries from "../../queries";
import * as LocalCartHelper from "../../helper/LocalCartHelper";
import { useSessionStorage } from "../../context/SessionStorageContext";

export async function handleAddProductToCart(
  updateSessionStorage,
  isUserLoggedIn,
  addProductToCart,
  productId,
  qty,
  options,
  refetch
) {
  try {
    if (isUserLoggedIn) {
      if (options) {
        const { data } = await addProductToCart({
          variables: {
            productId: productId,
            qty: qty,
            options: options,
          },
        });
        if (data?.addProductToCart?._id != null) {
          if (refetch != null) refetch();
          Swal.fire({
            icon: "success",
            title: "Product added to cart",
          }).then(() => {
            if (refetch == null) window.location.reload();
          });
        } else {
          throw "Something went wrong!";
        }
      } else {
        const { data } = await addProductToCart({
          variables: {
            productId: productId,
            qty: qty,
          },
        });
        if (data?.addProductToCart?._id != null) {
          if (refetch != null) refetch();
          Swal.fire({
            icon: "success",
            title: "Product added to cart",
          }).then(() => {
            if (refetch == null) window.location.reload();
          });
        } else {
          throw "Something went wrong!";
        }
      }
    } else {
      // add to local cart
      const cartItem = {
        product_id: productId,
        qty: qty,
        options: options,
      };
      LocalCartHelper.addToCart(updateSessionStorage, cartItem);
      Swal.fire({
        icon: "success",
        title: "Product added to cart",
      });
    }
  } catch (e) {
    if (typeof e == "string") {
      Swal.fire({
        icon: "error",
        title: e,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: e.message,
      });
    }
  }
}

export async function handleRemoveProductFromCart(
  updateSessionStorage,
  removeProductFromCart,
  isUserLoggedIn,
  cartItem
) {
  try {
    if (isUserLoggedIn) {
      if (cartItem == null) throw "Something went wrong!";
      const { data } = await removeProductFromCart({
        variables: {
          cartId: cartItem?._id,
        },
      });
      if (data?.removeProductFromCart != null) {
      } else {
        throw "Something went wrong!";
      }
    } else {
      LocalCartHelper.removeFromCart(updateSessionStorage, cartItem);
    }
  } catch (e) {
    if (typeof e == "string") {
      Swal.fire({
        icon: "error",
        title: e,
      });
    } else {
      if (
        e?.networkError?.result?.errors?.[0]?.extensions?.code ==
        "UNAUTHENTICATED"
      ) {
        Swal.fire({
          icon: "error",
          title: "Login to continue",
          text: "You need to be logged in to access cart",
        }).then(() => {
          //we can't use useNavigate here
          window.localStorage.clear();
          window.location.href = "/login";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: e.message,
        });
      }
    }
  }
}

function AddToCart({ productId, qty, type, options }) {
  const { sessionData, updateSessionStorage, isUserLoggedIn } =
    useSessionStorage();
  if (qty == null || isNaN(qty)) qty = 1;
  const [addProductToCart] = useMutation(queries.member.ADD_PRODUCT_TO_CART, {
    errorPolicy: "all",
  });

  const {
    data: getSelfData,
    loading,
    error: getSelfError,
  } = useQuery(queries.member.GET_SELF, {
    errorPolicy: "all",
  });
  const [cartId, setCartId] = useState();

  useEffect(() => {
    if (getSelfData?.getSelf?.cart != null) {
      const cart = getSelfData?.getSelf?.cart.filter(
        (cartItem) => productId != null && cartItem?.product?._id === productId
      );
      if (cart?.length > 0) {
        setCartId(cart[0]._id);
      }
    }
  }, [getSelfData, getSelfError]);

  const navigate = useNavigate();
  if (cartId != null) {
    if (type == "button") {
      return (
        <button
          className="btn btn-primary btn-icon btn-icon-left btn-svg-icon"
          type="button"
          onClick={() => {
            handleAddProductToCart(
              updateSessionStorage,
              isUserLoggedIn,
              addProductToCart,
              productId,
              qty,
              options
            );
          }}
        >
          <Link className="product-item-operation" to={{}}>
            <svg
              className="icon-svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20 20H0V6H20V20ZM2 18H18V8H2V18Z"></path>
              <path d="M14 3.99995H12C12 3.49995 11.8 2.99995 11.4 2.59995C10.7 1.89995 9.3 1.89995 8.6 2.59995C8.2 2.89995 8 3.39995 8 3.99995H6C6 2.89995 6.4 1.89995 7.2 1.19995C8.7 -0.300049 11.3 -0.300049 12.9 1.19995C13.6 1.89995 14 2.89995 14 3.99995Z"></path>
            </svg>
          </Link>
          Add to cart
        </button>
      );
    } else {
      return (
        <Link
          className="product-item-operation"
          to={{}}
          onClick={() => {
            handleAddProductToCart(
              updateSessionStorage,
              isUserLoggedIn,
              addProductToCart,
              productId,
              qty,
              options
            );
          }}
        >
          <svg
            className="icon-svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 20H0V6H20V20ZM2 18H18V8H2V18Z"></path>
            <path d="M14 3.99995H12C12 3.49995 11.8 2.99995 11.4 2.59995C10.7 1.89995 9.3 1.89995 8.6 2.59995C8.2 2.89995 8 3.39995 8 3.99995H6C6 2.89995 6.4 1.89995 7.2 1.19995C8.7 -0.300049 11.3 -0.300049 12.9 1.19995C13.6 1.89995 14 2.89995 14 3.99995Z"></path>
          </svg>
        </Link>
      );
    }
  } else if (productId != null) {
    if (type == "button") {
      return (
        <button
          className="btn btn-primary btn-icon btn-icon-left btn-svg-icon"
          type="button"
          onClick={() => {
            handleAddProductToCart(
              updateSessionStorage,
              isUserLoggedIn,
              addProductToCart,
              productId,
              qty,
              options
            );
          }}
        >
          <svg
            className="icon-svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 20H0V6H20V20ZM2 18H18V8H2V18Z"></path>
            <path d="M14 3.99995H12C12 3.49995 11.8 2.99995 11.4 2.59995C10.7 1.89995 9.3 1.89995 8.6 2.59995C8.2 2.89995 8 3.39995 8 3.99995H6C6 2.89995 6.4 1.89995 7.2 1.19995C8.7 -0.300049 11.3 -0.300049 12.9 1.19995C13.6 1.89995 14 2.89995 14 3.99995Z"></path>
          </svg>
          Add to cart
        </button>
      );
    } else {
      return (
        <Link
          className="product-item-operation"
          to={{}}
          onClick={() => {
            handleAddProductToCart(
              updateSessionStorage,
              isUserLoggedIn,
              addProductToCart,
              productId,
              qty,
              options
            );
          }}
        >
          <svg
            className="icon-svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 20H0V6H20V20ZM2 18H18V8H2V18Z"></path>
            <path d="M14 3.99995H12C12 3.49995 11.8 2.99995 11.4 2.59995C10.7 1.89995 9.3 1.89995 8.6 2.59995C8.2 2.89995 8 3.39995 8 3.99995H6C6 2.89995 6.4 1.89995 7.2 1.19995C8.7 -0.300049 11.3 -0.300049 12.9 1.19995C13.6 1.89995 14 2.89995 14 3.99995Z"></path>
          </svg>
        </Link>
      );
    }
  }
}

export default AddToCart;
