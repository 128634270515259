import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_ENC_SECRET; // Use a secure key

export const encrypt = (plainText) => {
  return CryptoJS.AES.encrypt(plainText, secretKey).toString();
};

export const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
