import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ImportMainJs, ImportScripts } from "../helper/ImportScript";
import Error404 from "./Error404";
import Footer from "./Footer";
import Header from "./Header/Header";
import Home from "./Home";
import ProductGrid from "./ProductGrid";
import Signup from "./Signup";
import Login from "./Login";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import SingleProduct from "./SingleProduct";
import Signout from "./Signout";
import Orders from "./Orders";
import Checkout from "./Checkout";
import AddProduct from "./AddProducts";
import ContactUs from "./ContactUs";
import QuickView from "./QuickView";
import { isUserAbove21 } from "../common";
import AboutUs from "./AboutUs";
import { useQuery } from "@apollo/client";
import queries from "../queries";
import ManageOrders from "./ManageOrders";
import ResetPassword from "./ResetPassword";
import AgeVerification from "./AgeVerification";

function Main(props) {
  const navigate = useNavigate();

  const {
    data: dataSelf,
    loading,
    error,
    refetch,
  } = useQuery(queries.member.GET_SELF, {
    errorPolicy: "all",
  });
  return (
    <div className="App">
      <div className="App-body">
        <div className="page">
          <Header dataSelf={dataSelf} refetch={refetch} error={error} />
          {!isUserAbove21() && <AgeVerification />}
          <Routes>
            <Route path="/" element={<Home refetch={refetch} />} />
            <Route
              path="/products"
              element={<ProductGrid refetch={refetch} />}
            />
            <Route
              path="/products/:type"
              element={<ProductGrid refetch={refetch} />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/cart"
              element={
                <Cart dataSelf={dataSelf} refetch={refetch} error={error} />
              }
            />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route
              path="/product"
              element={
                <SingleProduct
                  dataSelf={dataSelf}
                  refetch={refetch}
                  error={error}
                />
              }
            />
            <Route
              path="/product/:productId"
              element={
                <SingleProduct
                  dataSelf={dataSelf}
                  refetch={refetch}
                  error={error}
                />
              }
            />
            <Route
              path="/my-orders"
              element={
                <Orders dataSelf={dataSelf} refetch={refetch} error={error} />
              }
            />
            <Route path="/signout" element={<Signout />} />
            <Route
              path="/checkout"
              element={
                <Checkout
                  dataSelf={dataSelf}
                  loading={loading}
                  error={error}
                  refetch={refetch}
                />
              }
            />
            <Route
              path="/manage-orders"
              element={<ManageOrders dataSelf={dataSelf} />}
            />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/add-product/:productId" element={<AddProduct />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route
              path="/quickview"
              element={<QuickView productId="63a40f64155bc5334a961a80" />}
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Main;
